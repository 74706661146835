html {
    height: 100%;
}

body {
    min-height: 100%;
}

#root {
    height: 100vh;
    background-color: #F2F2F2;
    display: flex;
    flex-direction: row;
}


