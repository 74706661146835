.instructions-container {
    max-width: 80%; /* Adjust the value to the desired width */
    margin: 0 auto;
    padding: 20px;
  }
  
  .instructions-container h2 {
    margin-bottom: 20px;
  }
  
  .instructions-container p {
    margin-bottom: 30px;
  }
  